import React, { useEffect } from 'react'
import imageNlist from "./khub.jpg"

//Redux
import { useDispatch } from 'react-redux';
import { titleLabelAction } from '../../ReduxStore/Action/TitleLabelAction';

const KhunNLIST = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(titleLabelAction({ titleLabel: "K-HUB" }))
    }, [])

    return (
        <>
            {/* <iframe src="https://nlist.inflibnet.ac.in?igu=1" frameBorder={0} width={"100%"} height={"500px"} ></iframe> */}


            <a href="http://k-hub.in" target="_blank" rel="noopener noreferrer">
                <img src={imageNlist} alt="" className='img-fluid'
                    style={{ userSelect: "none" }}
                />
            </a>

        </>
    )
}

export default KhunNLIST